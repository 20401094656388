import * as React from "react";
import PageLayout from "../../PageLayout";
import { Helmet } from "react-helmet";
import PrevNext from "../../components/PrevNext";
import SectionLayout from "../../SectionLayout";
import background from "../../images/previousprojects/6.jpg"
const Laramba = () => {
    return (
        <PageLayout
            noButton
            navSize={"h-96"}
            image={background}
            title={"Laramba Borefield Power Station"}
            titleStyles={"xl:mt-14 lg:mt-24 md:mt-24 sm:mt-24 xs:mt-20 mt-16"}
        >
            <Helmet>
                <title>
                    Laramba Borefield Power Station | EPG Solutions
                </title>
                <meta
                    name="description"
                    content="Client: Power Water &#8211; Remote Water Services Completed: 2019-20 Location: Laramba, Central Australia (205km NW of Alice Springs) Scope: Design and construct a 10-foot containerised generator control room with full automated start and stop of two Cummins generators. (Synchronising, load shearing, and generator protection). The power station receives a start command from the Miri Telemetry."
                />
            </Helmet>
            <div className={"bg-epg-yellow h-5"} />
            <SectionLayout bgColor={"bg-epg-white"}>
                <div className={" py-5"}>
                    <div className={"px-2 mb-10  lg:leading-8 lg:text-lg"}>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Client:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              Power Water – Remote Water Services
            </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Completed:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              2019-20
            </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Location:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              Laramba, Central Australia (205km NW of Alice Springs)
            </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Scope:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              Design and construct a 10-foot containerised generator control
              room with full automated start and stop of two Cummins generators.
              (Synchronising, load shearing, and generator protection). The
              power station receives a start command from the Miri Telemetry
              System, station goes online and the bore pumps is automatically
              activated once their dead bus delay relays are energised pumping
              water to the community main water tank. Once the desired level in
              the main water tank is reached are the bore pumps stopped, and
              generators placed in a cooldown stop mode. The Power Stations
              control batteries (Bank A & B) are kept charged by solar power
              when not in use. The Power Station includes full SCADA interface
              back to Alice Springs to allow remote monitoring.
            </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Challenges:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              Remote location, logistics for materials.
            </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Outcome:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              On time, on budget, satisfied client.
            </span>
                        </p>
                    </div>
                    <PrevNext
                        prev={"eva-valley-power-station/"}
                        next={"chlorine-containers-disinfection-program/"}
                    />
                </div>
            </SectionLayout>
        </PageLayout>
    );
};

export default Laramba;
